import { datadogRum } from '@datadog/browser-rum';

const BOT_USERAGENT_REGEX =
  /(Googlebot|Applebot|bingbot|Storebot|BrightEdge Crawler|IVW-Crawler|YandexRenderResourcesBot|Bytespider|SeekportBot|YandexMobileBot|AdsBot-Google|AppEngine-Google|BingPreview|Baiduspider-render|FacebookBot)/;

export default ({ $config: { datadogEnv } }) => {
  if (!datadogEnv) return;

  const isBot = BOT_USERAGENT_REGEX.test(navigator.userAgent);
  const sessionSampleRate = isBot ? 0 : 50;

  datadogRum.init({
    applicationId: 'e9b9ac7e-e1e9-49b8-9b64-bbf34105498d',
    clientToken: 'pub648e8c23b7789c3f2ac6a929c16a9469',
    site: 'datadoghq.eu',
    service: 'frontend',
    env: datadogEnv,
    sessionSampleRate,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  datadogRum.startSessionReplayRecording();
};
