import { Context } from '@nuxt/types';
import { Logger } from '@vue-storefront/core';
import { extractPathFromUrl } from '~/helpers/utils';

export default async function (context: Context) {
  const url = process.server ? context.req.url : context.route.path;
  const query = process.server
    ? (context.req as any).query
    : context.route.query;
  const urlInfoQuery = query?.preview ? { preview: query.preview } : {};
  const pathName = extractPathFromUrl(url);
  try {
    const urlInfo = await context.app.$vsf.$novulo.api.getURLInfo(
      pathName,
      urlInfoQuery
    );
    if (urlInfo) {
      if (urlInfo.response_type === 301) {
        context.redirect(urlInfo.redirect_url);
      }
      context.store.dispatch('page/setUrlInfo', urlInfo);
    } else {
      context.error({ statusCode: 404 });
    }
  } catch (error) {
    Logger.error('[pagerouter] urlInfo not found', pathName);
    context.error({ statusCode: 404, message: error.message });
  }
}
